<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{
              name: 'DashboardHome',
            }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'OrganizationHome',
              }"
            >
              組織資料設定
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'ModuleList',
              }"
            >
              模組管理
            </b-breadcrumb-item>
            <b-breadcrumb-item active>
              {{
                isEdit ? "模組編輯" : isShow ? "查看模組" : "新增模組"
              }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row mb-2 align-items-end">
        <div class="col-8 d-flex items-center">
          <h4 class="font-weight-bold">{{ isEdit ? `${organizationName}模組編輯` : isShow ? `${organizationName}查看模組` : `${organizationName}新增模組` }}</h4>
        </div>
      </div>

      <b-overlay :show="isLoading" rounded opacity="0.6" spinner-small spinner-variant="primary">
        <section class="row mb-8">
          <div class="col-12 col-xl-8">
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="模組名稱"
              label-for="module_name"
            >
              <b-form-input
                id="module_code"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="text"
                v-model="state.form.name"
                readonly
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="模組代號"
              label-for="module_code"
            >
              <b-form-input
                id="module_code"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="text"
                v-model="state.form.module_code"
                :state="v$.form.module_code.$error ? false : null"
                :readonly="isShow || isEdit"
              ></b-form-input>
              <b-form-invalid-feedback :state="!v$.form.module_code.$error">此欄位為必填</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="通路"
              label-for="merchant"
            >
              <b-form-select
                v-model="state.form.merchant_id"
                :options="merchants"
                :disabled="isFetchMerchants || isShow"
              ></b-form-select>
              <b-form-invalid-feedback :state="!v$.form.merchant_id.$error">此欄位為必填</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="模組設定"
              label-for="module_config"
            >
              <editor
                id="module_config"
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="state.form.module_config"
                :disabled="isShow"
                width="100%"
                height="200px"
                @init="editorInit"
                lang="json"
                theme="chrome"
              >
              </editor>
              <b-form-invalid-feedback :state="!v$.form.module_config.$error">
                請檢查此欄位資料是否為正確JSON格式
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="是否啟用"
              label-for="is_enabled"
            >
              <b-form-checkbox
                id="is_enabled"
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="state.form.is_enabled"
                name="is_enabled"
                :value="1"
                :unchecked-value="0"
                :disabled="isShow"
              ></b-form-checkbox>
            </b-form-group>
          </div>
        </section>
      </b-overlay>

      <div class="d-flex justify-content-center">
        <div class="d-flex justify-content-center" v-if="isShow">
          <b-button
            class="mr-3"
            @click="handleCancel"
            variant="danger"
          >返回
          </b-button>
        </div>
        <b-button class="mr-4" variant="outline-danger" @click="handleCancel" v-if="!isShow">取消</b-button>
        <b-overlay
          :show="isSubmmiting"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button class="mr-2" variant="success" @click="handleSubmit(false)" v-if="isEdit">
            儲存
          </b-button>
          <b-button variant="success" @click="handleSubmit" v-if="!isShow">
            {{
              isEdit ? "儲存後關閉" : "確認新增"
            }}
          </b-button>
        </b-overlay>
      </div>
    </b-card>
  </div>
</template>

<script>
import { reactive } from "@vue/composition-api";
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import moduleApi from "../../../apis/module";
import merchantApi from "../../../apis/merchant";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import jsonEditorMixin from "@/mixins/jsonEditor";

const mustBeJson = (value) => {
  if (!helpers.req(value)) return true;
  try {
    if (typeof value === 'string')
      JSON.parse(value)
    return true;
  } catch (e) {
    return false;
  }
};

export default {
  mixins: [jsonEditorMixin],
  setup() {
    const state = reactive({
      form: {
        module_code: "",
        module_config: "",
        merchant_id:  null,
        is_enabled: 0,
      },
    });
    const rules = {
      form: {
        module_code: { required },
        merchant_id: { required },
        module_config: { mustBeJson },
      },
    };

    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },
  data() {
    return {
      isLoading: false,
      isSubmmiting: false,
      isFetchMerchants: false,
      merchants: [{ value: null, text: "請選擇" }],
      form: {
        module_code: "",
        module_config: "",
        merchant_id: null,
        is_enabled: 0,
        name: "",
      },
    };
  },
  validations() {
    return {
      form: {
        module_code: { required },
        module_config: { mustBeJson },
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      organizationName: (state) => state.organization.name || ''
    }),
    orgId() {
      return this.$route.params.orgId;
    },
    moduleId() {
      return this.$route.params.moduleId;
    },
    isEdit() {
      return this.$route.name === "ModuleEdit";
    },
    isShow() {
      return this.$route.name === "ModuleShow";
    },
  },
  mounted() {
    if (!this.checkPermission([consts.MERCHANT_MODIFY])) {
      this.$swal
        .fire({
          type: "error",
          text: "你沒有權限訪問此頁面",
          confirmButtonColor: "#d33"
        })
        .then(() => {
          this.$router.push({ name: "ModuleList" });
        });
    } else if (this.moduleId) {
      this.fetchModule();
    }
    this.getMerchants();
  },
  methods: {
    async getMerchants() {
      this.isFetchMerchants = true;
      const { data } = await merchantApi.list();
      this.isFetchMerchants = false;

      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: merchant.type_name
              ? `${merchant.name} (${merchant.type_name})`
              : merchant.name,
          };
        }),
      ];
    },
    async fetchModule() {
      try {
        this.isLoading = true;
        const { data } = await moduleApi.getModule(this.orgId, this.moduleId);
        const { module_code, merchant, is_enabled, module_config, name } = data.data;

        if (this.isEdit || this.isShow) {
          this.form = {
            ...this.form,
            merchant_id: merchant.id,
            module_config: JSON.stringify(module_config, null, 2),
            is_enabled: is_enabled ? 1 : 0,
            name,
          };
          this.state.form = {
            module_code,
            merchant_id: merchant.id,
            module_config: JSON.stringify(module_config, null, 2),
            is_enabled: is_enabled ? 1 : 0,
            name,
          };
        }
      } catch (error) {
        console.log("error", error);
        console.log("");
      }
      this.isLoading = false;
    },
    async handleSubmit(redirect = true) {
      const result = await this.v$.$validate();
      if (!result) return;
      if (this.isEdit) {
        this.handleUpdate(redirect);
      } else {
        this.handleCreate();
      }
    },
    async handleUpdate(redirect = true) {
      try {
        this.isSubmmiting = true;
        let module = {
          ...this.form,
          ...this.state.form,
        }
        if (typeof module.module_config === 'string') {
          module.module_config = JSON.parse(module.module_config);
        }
        await moduleApi.updateModule(this.orgId, this.moduleId, module);
        this.showSuccessPopup(redirect);
      } catch (error) {
        const { status, data } = error.response;
        if (status && status === 422 && data.message) {
          const html = Object.values(data.message)
            .map((m) => m[0])
            .join("<br/>");
          this.$swal.fire({
            type: "error",
            html,
          });
        }
      }
      this.isSubmmiting = false;
    },
    async handleCreate() {
      try {
        this.isSubmmiting = true;
        let module = {
          ...this.form,
          ...this.state.form,
        }
        if (typeof module.module_config === 'string') {
          module.module_config = JSON.parse(module.module_config);
        }
        await moduleApi.createModule(this.orgId, module);
        this.showSuccessPopup();
      } catch (error) {
        const { status, data } = error.response;
        if (status && status === 422 && data.message) {
          const html = Object.values(data.message)
            .map((m) => m[0])
            .join("<br/>");
          this.$swal.fire({
            type: "error",
            html,
          });
        }
      }
      this.isSubmmiting = false;
    },
    handleCancel() {
      this.$router.push({ name: "ModuleList" });
    },
    showSuccessPopup(redirect = true) {
      this.$swal
        .fire({
          type: "success",
          text: this.isEdit ? "修改成功" : "新增成功",
        })
        .then(() => {
          if (redirect) {
            this.$router.push({ name: "ModuleList" });
          }
        });
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    }
  },
};
</script>
